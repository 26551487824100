import React from "react";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemState,
  AccordionItemPanel,
} from "react-accessible-accordion";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import Testimonials from "../components/Repeating/Testimonials";
import Sidebar from "../components/Repeating/SidebarCta";
import Attorneys from "../components/Repeating/Attorneys";
import WhyUs from "../components/Repeating/WhyUs";
import CallToAction from "../components/Repeating/CTA";
import ButtonSolid from "../components/Button/ButtonSolid";
import ButtonGhost from "../components/Button/ButtonGhost";

const Page = ({ data }) => {
  const accordionContent = [
    {
      title: "Carlsbad Conservatorship Lawyer",
      content: (
        <>
          <p>
            A conservatorship allows you to legally handle a loved one's affairs
            when they are physically or mentally unable to take care of
            themselves. You would have a legal duty to oversee their finances,
            personal care, or both responsibly.
          </p>
          <p>
            If you’re facing this type of situation, our conservatorship lawyers
            in Carlsbad have decades of expertise and are here to help guide you
            through the process.
          </p>
          <a href="https://briertonjones.com/conservatorship-lawyer/">
            Learn More About Conservatorship
          </a>
        </>
      ),
    },
    {
      title: "Carlsbad Guardianship Lawyer",
      content: (
        <>
          <p>
            When parents are incapable of making vital decisions to care for a
            child, the legal system can intervene by appointing a guardian. In
            such instances, the parents retain their parental rights and can ask
            for reasonable contact or visitation, but a guardian supervised by
            the court takes over the responsibility of ensuring the child is
            well cared for.
          </p>
          <p>
            At Brierton, Jones & Jones, LLP, we know that securing guardianship
            to ensure a child’s well-being can be a complex and emotionally
            charged journey. Our Del Mar attorneys are here to help make it as
            smooth and seamless as possible— let our seasoned legal team be your
            guide through the process.
          </p>
          <a href="https://briertonjones.com/guardianship-lawyer/">
            Learn More About Guardianship
          </a>
        </>
      ),
    },
    {
      title: "Carlsbad Special Needs Trust Lawyer",
      content: (
        <>
          <p>
            If you are the parent of a child with a disability, a special needs
            trust can ensure they are taken care of after you pass away. It will
            set aside assets for your beneficiary and put them in the control of
            a trustee. A special needs trust can also allow a disabled adult to
            continue receiving government benefits like SSI and Medi-Cal.
          </p>
          <p>
            Careful estate planning is critical when you have a disabled child,
            and a special needs trust is a key component of that plan. It’s a
            way to ensure your loved one continues to have access to the
            resources they need.
          </p>
          <p>
            Secure their future by speaking with one of our experienced special
            needs trust lawyers in Carlsbad. We’re happy to explain it all to
            you and guide you through the process.
          </p>
          <a href="https://briertonjones.com/special-needs-trust-lawyer/">
            Learn More About Special Needs Trust
          </a>
        </>
      ),
    },
  ];

  return (
    <Layout>
      <SearchEngineOptimization
        title="Trust Administration Services Carlsbad | BJ&J"
        description="Secure your legacy with trusted trust administration services in Carlsbad. BJ&J ensures smooth estate transitions. Learn more about our expert legal assistance."
        openGraphImage={data.openGraphImage.publicURL}
        twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />
      <article>
        <section className="bg-gray-50 pb-6 pt-12 md:pb-16 md:pt-10">
          <div className="relative">
            <div className="container">
              <div className="grid items-center gap-y-14 md:min-h-[506px] md:grid-cols-2">
                <div className="md:pr-6 lg:pr-12">
                  <div className="mb-6 text-sm font-bold uppercase tracking-wide text-gray-300">
                    SECURE YOUR LEGACY
                  </div>
                  <h1>Trust Administration Services in Carlsbad</h1>
                  <p>
                    Whether you need assistance with asset distribution or help
                    settling debts and taxes, Brierton, Jones & Jones, LLP is
                    here to assist. With over 30 years of experience in trust
                    administration, our lawyers remain dedicated to serving the
                    community of Carlsbad with trust, compassion, and respect.
                  </p>
                  <ButtonSolid
                    modal="modal-contact"
                    text="Schedule a Consultation"
                  />
                </div>

                <div className="-mr-4 md:absolute md:right-0 md:top-0 md:mr-0 md:h-full md:w-[45vw] md:pl-6 lg:pl-12">
                  <StaticImage
                    src="../images/city pages/Carlsbad.png"
                    loading="eager"
                    className="h-full"
                    imgClassName="object-left"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="mb-20 pt-14 md:mb-32 md:pt-20">
          <div className="container">
            <div className="flex justify-between md:space-x-14 lg:space-x-28">
              <div>
                <div className="mb-12 md:mb-18">
                  <h2>Why You Need a Trust Administration Lawyer</h2>
                  <p>
                    If 30 years of experience have shown us anything, it's that
                    trust cases can be legally and emotionally overwhelming —
                    especially when placed in the wrong hands.
                  </p>
                  <p>
                    That’s why those who live and work in Carlsbad have relied
                    on the Brierton, Jones & Jones, LLP legal team for over 30
                    years.
                  </p>
                  <p>
                    We simplify intricate trust administration cases with a
                    personalized approach, upholding your wishes and protecting
                    your loved ones' interests. With us, your trust
                    administration case is in reliable and caring hands—a
                    commitment we've upheld for over three decades.
                  </p>
                </div>

                <div>
                  <h3>We’re with You Every Step of the Way</h3>
                  <p>
                    Successful trust administration demands experience, empathy,
                    and expertise. That's precisely how we approach every step
                    of your case, from asset inventory and distribution to
                    accounting, tax payment, and resolution.
                  </p>
                </div>
                <div>
                  <h3>Peace of Mind Demands Swift Resolution</h3>
                  <p>
                    Legal mistakes can be frustrating and costly. Our approach
                    ensures swift case resolution, free from such errors,
                    providing the peace of mind you and your family deserve.
                  </p>
                </div>
                <div className="pt-[120px]">
                  <h3>Related Practice Areas</h3>
                  <Accordion
                    allowZeroExpanded={true}
                    className="flex flex-col space-y-5 border-t border-gray-100 pt-5"
                  >
                    {accordionContent.map((item, i) => (
                      <AccordionItem
                        uuid={i}
                        className="border-b border-gray-100 pb-5"
                      >
                        <AccordionItemButton className="flex items-center justify-between focus:outline-none">
                          <AccordionItemState>
                            {(state) => {
                              return (
                                <>
                                  <h3 className="mb-0 font-display text-xl font-medium text-typography-heading no-underline">
                                    {item.title}
                                  </h3>
                                  <i
                                    className={`far ml-2 text-xl text-seafoam ${
                                      state.expanded
                                        ? "fa-minus"
                                        : "fa-chevron-down"
                                    }`}
                                  ></i>
                                </>
                              );
                            }}
                          </AccordionItemState>
                        </AccordionItemButton>

                        <AccordionItemPanel className="pt-5">
                          {item.content}
                        </AccordionItemPanel>
                      </AccordionItem>
                    ))}
                  </Accordion>
                  <ButtonSolid
                    modal="modal-contact"
                    text="Contact us"
                    className="mt-8"
                  />
                </div>
              </div>

              <div className="hidden md:block">
                <div className="sticky top-24">
                  <aside>
                    <div className="ml-auto mt-14 w-[298px] rounded-3xl border border-seafoam px-4 py-6">
                      <div className="mb-4 font-display text-2xl font-semibold text-typography-heading">
                        Schedule a Consultation
                      </div>
                      <p className="font-display text-lg">
                        When you need trust administration services from a
                        Carlsbad attorney, Brierton, Jones & Jones, LLP is here
                        for you.
                      </p>

                      <div className="space-y-4">
                        <ButtonGhost
                          href="tel:619-485-5394"
                          text="(619) 485-5394"
                          className="w-full"
                        />
                        <ButtonSolid
                          modal="modal-contact"
                          text="Send a Message"
                          className="w-full"
                        />
                      </div>
                    </div>
                  </aside>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container mb-20">
            <StaticImage
              src="../images/city pages/Carlsbad Map.jpg"
              loading="eager"
              className="w-full"
            />
          </div>
        </section>
      </article>
      <Attorneys />
      <WhyUs />
      <Testimonials />
      <CallToAction text="When you need a San Diego estate planning attorney to help you put everything in order, Brierton, Jones & Jones, LLP is here for you." />
    </Layout>
  );
};

export const query = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "Global/Open Graphs/Facebook/01 Estate Planning.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "Global/Open Graphs/Twitter/01 Estate Planning.jpg" }
    ) {
      publicURL
    }
  }
`;

export default Page;
